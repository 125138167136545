export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const IoSocketStatus = () => import('../../node_modules/nuxt-socket-io/lib/components/SocketStatus.js' /* webpackChunkName: "components/io-socket-status" */).then(c => wrapFunctional(c.default || c))
export const BContainer = () => import('../../components/BContainer.vue' /* webpackChunkName: "components/b-container" */).then(c => wrapFunctional(c.default || c))
export const EmptyContentAlternative = () => import('../../components/EmptyContentAlternative.vue' /* webpackChunkName: "components/empty-content-alternative" */).then(c => wrapFunctional(c.default || c))
export const MachinationsShapesBackground = () => import('../../components/MachinationsShapesBackground.vue' /* webpackChunkName: "components/machinations-shapes-background" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const AnimatedNumber = () => import('../../components/_animatedNumber.vue' /* webpackChunkName: "components/animated-number" */).then(c => wrapFunctional(c.default || c))
export const TrackingNoscript = () => import('../../components/_trackingNoscript.vue' /* webpackChunkName: "components/tracking-noscript" */).then(c => wrapFunctional(c.default || c))
export const AboutContactForm = () => import('../../components/about/AboutContactForm.vue' /* webpackChunkName: "components/about-contact-form" */).then(c => wrapFunctional(c.default || c))
export const AboutHero = () => import('../../components/about/AboutHero.vue' /* webpackChunkName: "components/about-hero" */).then(c => wrapFunctional(c.default || c))
export const AboutMission = () => import('../../components/about/AboutMission.vue' /* webpackChunkName: "components/about-mission" */).then(c => wrapFunctional(c.default || c))
export const AboutMissionList = () => import('../../components/about/AboutMissionList.vue' /* webpackChunkName: "components/about-mission-list" */).then(c => wrapFunctional(c.default || c))
export const AboutTimelineEventList = () => import('../../components/about/TimelineEventList.vue' /* webpackChunkName: "components/about-timeline-event-list" */).then(c => wrapFunctional(c.default || c))
export const AboutTimelineItem = () => import('../../components/about/TimelineItem.vue' /* webpackChunkName: "components/about-timeline-item" */).then(c => wrapFunctional(c.default || c))
export const AcquisitionCTA = () => import('../../components/acquisition/AcquisitionCTA.vue' /* webpackChunkName: "components/acquisition-c-t-a" */).then(c => wrapFunctional(c.default || c))
export const AcquisitionFAQ = () => import('../../components/acquisition/AcquisitionFAQ.vue' /* webpackChunkName: "components/acquisition-f-a-q" */).then(c => wrapFunctional(c.default || c))
export const AcquisitionHero = () => import('../../components/acquisition/AcquisitionHero.vue' /* webpackChunkName: "components/acquisition-hero" */).then(c => wrapFunctional(c.default || c))
export const AupHero = () => import('../../components/aup/AupHero.vue' /* webpackChunkName: "components/aup-hero" */).then(c => wrapFunctional(c.default || c))
export const CareersDescriptionSection = () => import('../../components/careers/CareersDescriptionSection.vue' /* webpackChunkName: "components/careers-description-section" */).then(c => wrapFunctional(c.default || c))
export const CareersJobList = () => import('../../components/careers/CareersJobList.vue' /* webpackChunkName: "components/careers-job-list" */).then(c => wrapFunctional(c.default || c))
export const BlogAcfBlocks = () => import('../../components/blog/BlogAcfBlocks.vue' /* webpackChunkName: "components/blog-acf-blocks" */).then(c => wrapFunctional(c.default || c))
export const BlogAuthorHero = () => import('../../components/blog/BlogAuthorHero.vue' /* webpackChunkName: "components/blog-author-hero" */).then(c => wrapFunctional(c.default || c))
export const BlogCategoriesMenu = () => import('../../components/blog/BlogCategoriesMenu.vue' /* webpackChunkName: "components/blog-categories-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogHero = () => import('../../components/blog/BlogHero.vue' /* webpackChunkName: "components/blog-hero" */).then(c => wrapFunctional(c.default || c))
export const BlogListPost = () => import('../../components/blog/BlogListPost.vue' /* webpackChunkName: "components/blog-list-post" */).then(c => wrapFunctional(c.default || c))
export const CommonAcf = () => import('../../components/common/CommonAcf.vue' /* webpackChunkName: "components/common-acf" */).then(c => wrapFunctional(c.default || c))
export const CommonActivityFeedRight = () => import('../../components/common/CommonActivityFeedRight.vue' /* webpackChunkName: "components/common-activity-feed-right" */).then(c => wrapFunctional(c.default || c))
export const CommonCardLinkPage = () => import('../../components/common/CommonCardLinkPage.vue' /* webpackChunkName: "components/common-card-link-page" */).then(c => wrapFunctional(c.default || c))
export const CommonCardWebinar = () => import('../../components/common/CommonCardWebinar.vue' /* webpackChunkName: "components/common-card-webinar" */).then(c => wrapFunctional(c.default || c))
export const CommonCommunity = () => import('../../components/common/CommonCommunity.vue' /* webpackChunkName: "components/common-community" */).then(c => wrapFunctional(c.default || c))
export const CommonContextMenu = () => import('../../components/common/CommonContextMenu.vue' /* webpackChunkName: "components/common-context-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomDiagramCardViewer = () => import('../../components/common/CommonCustomDiagramCardViewer.vue' /* webpackChunkName: "components/common-custom-diagram-card-viewer" */).then(c => wrapFunctional(c.default || c))
export const CommonDiagramCardNuxt = () => import('../../components/common/CommonDiagramCardNuxt.vue' /* webpackChunkName: "components/common-diagram-card-nuxt" */).then(c => wrapFunctional(c.default || c))
export const CommonEducationBrandsBlock = () => import('../../components/common/CommonEducationBrandsBlock.vue' /* webpackChunkName: "components/common-education-brands-block" */).then(c => wrapFunctional(c.default || c))
export const CommonFeaturesBlock = () => import('../../components/common/CommonFeaturesBlock.vue' /* webpackChunkName: "components/common-features-block" */).then(c => wrapFunctional(c.default || c))
export const CommonHomeRegister = () => import('../../components/common/CommonHomeRegister.vue' /* webpackChunkName: "components/common-home-register" */).then(c => wrapFunctional(c.default || c))
export const CommonImageBlock = () => import('../../components/common/CommonImageBlock.vue' /* webpackChunkName: "components/common-image-block" */).then(c => wrapFunctional(c.default || c))
export const CommonImg = () => import('../../components/common/CommonImg.vue' /* webpackChunkName: "components/common-img" */).then(c => wrapFunctional(c.default || c))
export const CommonInfiniteLoader = () => import('../../components/common/CommonInfiniteLoader.vue' /* webpackChunkName: "components/common-infinite-loader" */).then(c => wrapFunctional(c.default || c))
export const CommonInfoBlock = () => import('../../components/common/CommonInfoBlock.vue' /* webpackChunkName: "components/common-info-block" */).then(c => wrapFunctional(c.default || c))
export const CommonInteractiveDiagram = () => import('../../components/common/CommonInteractiveDiagram.vue' /* webpackChunkName: "components/common-interactive-diagram" */).then(c => wrapFunctional(c.default || c))
export const CommonLoadingSplash = () => import('../../components/common/CommonLoadingSplash.vue' /* webpackChunkName: "components/common-loading-splash" */).then(c => wrapFunctional(c.default || c))
export const CommonLogoBelt = () => import('../../components/common/CommonLogoBelt.vue' /* webpackChunkName: "components/common-logo-belt" */).then(c => wrapFunctional(c.default || c))
export const CommonPagination = () => import('../../components/common/CommonPagination.vue' /* webpackChunkName: "components/common-pagination" */).then(c => wrapFunctional(c.default || c))
export const CommonParagraphsBlock = () => import('../../components/common/CommonParagraphsBlock.vue' /* webpackChunkName: "components/common-paragraphs-block" */).then(c => wrapFunctional(c.default || c))
export const CommonPerksGallery = () => import('../../components/common/CommonPerksGallery.vue' /* webpackChunkName: "components/common-perks-gallery" */).then(c => wrapFunctional(c.default || c))
export const CommonQuote = () => import('../../components/common/CommonQuote.vue' /* webpackChunkName: "components/common-quote" */).then(c => wrapFunctional(c.default || c))
export const CommonSalesDialog = () => import('../../components/common/CommonSalesDialog.vue' /* webpackChunkName: "components/common-sales-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonScrollTop = () => import('../../components/common/CommonScrollTop.vue' /* webpackChunkName: "components/common-scroll-top" */).then(c => wrapFunctional(c.default || c))
export const CommonSmallCardLink = () => import('../../components/common/CommonSmallCardLink.vue' /* webpackChunkName: "components/common-small-card-link" */).then(c => wrapFunctional(c.default || c))
export const CommonSocialMediaCards = () => import('../../components/common/CommonSocialMediaCards.vue' /* webpackChunkName: "components/common-social-media-cards" */).then(c => wrapFunctional(c.default || c))
export const CommonTags = () => import('../../components/common/CommonTags.vue' /* webpackChunkName: "components/common-tags" */).then(c => wrapFunctional(c.default || c))
export const CommonTalkToTeam = () => import('../../components/common/CommonTalkToTeam.vue' /* webpackChunkName: "components/common-talk-to-team" */).then(c => wrapFunctional(c.default || c))
export const CommonTestimonialsBlock = () => import('../../components/common/CommonTestimonialsBlock.vue' /* webpackChunkName: "components/common-testimonials-block" */).then(c => wrapFunctional(c.default || c))
export const CommonTextBlock = () => import('../../components/common/CommonTextBlock.vue' /* webpackChunkName: "components/common-text-block" */).then(c => wrapFunctional(c.default || c))
export const CommonTextWithCardsBlock = () => import('../../components/common/CommonTextWithCardsBlock.vue' /* webpackChunkName: "components/common-text-with-cards-block" */).then(c => wrapFunctional(c.default || c))
export const CommonTwitter = () => import('../../components/common/CommonTwitter.vue' /* webpackChunkName: "components/common-twitter" */).then(c => wrapFunctional(c.default || c))
export const CommonVideoContainer = () => import('../../components/common/CommonVideoContainer.vue' /* webpackChunkName: "components/common-video-container" */).then(c => wrapFunctional(c.default || c))
export const CommonVideoPlayerDialog = () => import('../../components/common/CommonVideoPlayerDialog.vue' /* webpackChunkName: "components/common-video-player-dialog" */).then(c => wrapFunctional(c.default || c))
export const CommonVsCompetitorTable = () => import('../../components/common/CommonVsCompetitorTable.vue' /* webpackChunkName: "components/common-vs-competitor-table" */).then(c => wrapFunctional(c.default || c))
export const CommonCustomCardsBlock = () => import('../../components/common/CustomCardsBlock.vue' /* webpackChunkName: "components/common-custom-cards-block" */).then(c => wrapFunctional(c.default || c))
export const CommunityCardCarousel = () => import('../../components/community/CommunityCardCarousel.vue' /* webpackChunkName: "components/community-card-carousel" */).then(c => wrapFunctional(c.default || c))
export const CommunityCardGrid = () => import('../../components/community/CommunityCardGrid.vue' /* webpackChunkName: "components/community-card-grid" */).then(c => wrapFunctional(c.default || c))
export const CommunityFollowBtn = () => import('../../components/community/CommunityFollowBtn.vue' /* webpackChunkName: "components/community-follow-btn" */).then(c => wrapFunctional(c.default || c))
export const CommunitySocialShare = () => import('../../components/community/CommunitySocialShare.vue' /* webpackChunkName: "components/community-social-share" */).then(c => wrapFunctional(c.default || c))
export const CommunityScrollToTop = () => import('../../components/community/ScrollToTop.vue' /* webpackChunkName: "components/community-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const DesignerOfTheMonth = () => import('../../components/designer/DesignerOfTheMonth.vue' /* webpackChunkName: "components/designer-of-the-month" */).then(c => wrapFunctional(c.default || c))
export const DiagramForkBtn = () => import('../../components/diagram/DiagramForkBtn.vue' /* webpackChunkName: "components/diagram-fork-btn" */).then(c => wrapFunctional(c.default || c))
export const DiagramLikeBtn = () => import('../../components/diagram/DiagramLikeBtn.vue' /* webpackChunkName: "components/diagram-like-btn" */).then(c => wrapFunctional(c.default || c))
export const DiagramTagNav = () => import('../../components/diagram/DiagramTagNav.vue' /* webpackChunkName: "components/diagram-tag-nav" */).then(c => wrapFunctional(c.default || c))
export const DiagramTags = () => import('../../components/diagram/DiagramTags.vue' /* webpackChunkName: "components/diagram-tags" */).then(c => wrapFunctional(c.default || c))
export const DialogsBasicDialog = () => import('../../components/dialogs/BasicDialog.vue' /* webpackChunkName: "components/dialogs-basic-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsContactDialog = () => import('../../components/dialogs/ContactDialog.vue' /* webpackChunkName: "components/dialogs-contact-dialog" */).then(c => wrapFunctional(c.default || c))
export const DialogsWrapper = () => import('../../components/dialogs/DialogsWrapper.vue' /* webpackChunkName: "components/dialogs-wrapper" */).then(c => wrapFunctional(c.default || c))
export const DialogsLoginDialog = () => import('../../components/dialogs/LoginDialog.vue' /* webpackChunkName: "components/dialogs-login-dialog" */).then(c => wrapFunctional(c.default || c))
export const DocsWrapper = () => import('../../components/docs/DocsWrapper.vue' /* webpackChunkName: "components/docs-wrapper" */).then(c => wrapFunctional(c.default || c))
export const GlossaryChapter = () => import('../../components/glossary/GlossaryChapter.vue' /* webpackChunkName: "components/glossary-chapter" */).then(c => wrapFunctional(c.default || c))
export const GlossaryHomepage = () => import('../../components/glossary/GlossaryHomepage.vue' /* webpackChunkName: "components/glossary-homepage" */).then(c => wrapFunctional(c.default || c))
export const GlossaryRelatedGlossaryTerms = () => import('../../components/glossary/RelatedGlossaryTerms.vue' /* webpackChunkName: "components/glossary-related-glossary-terms" */).then(c => wrapFunctional(c.default || c))
export const HeaderCommunitySubMenu = () => import('../../components/header/CommunitySubMenu.vue' /* webpackChunkName: "components/header-community-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderItemCard = () => import('../../components/header/HeaderItemCard.vue' /* webpackChunkName: "components/header-item-card" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavBar = () => import('../../components/header/HeaderNavBar.vue' /* webpackChunkName: "components/header-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileCommunitySubMenu = () => import('../../components/header/MobileCommunitySubMenu.vue' /* webpackChunkName: "components/header-mobile-community-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileMenu = () => import('../../components/header/MobileMenu.vue' /* webpackChunkName: "components/header-mobile-menu" */).then(c => wrapFunctional(c.default || c))
export const HomepageArticle = () => import('../../components/homepage/HomepageArticle.vue' /* webpackChunkName: "components/homepage-article" */).then(c => wrapFunctional(c.default || c))
export const HomepageArticles = () => import('../../components/homepage/HomepageArticles.vue' /* webpackChunkName: "components/homepage-articles" */).then(c => wrapFunctional(c.default || c))
export const HomepageCommunitySection = () => import('../../components/homepage/HomepageCommunitySection.vue' /* webpackChunkName: "components/homepage-community-section" */).then(c => wrapFunctional(c.default || c))
export const HomepageEconomySection = () => import('../../components/homepage/HomepageEconomySection.vue' /* webpackChunkName: "components/homepage-economy-section" */).then(c => wrapFunctional(c.default || c))
export const HomepageFoundationSection = () => import('../../components/homepage/HomepageFoundationSection.vue' /* webpackChunkName: "components/homepage-foundation-section" */).then(c => wrapFunctional(c.default || c))
export const HomepageGamePreview = () => import('../../components/homepage/HomepageGamePreview.vue' /* webpackChunkName: "components/homepage-game-preview" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeader = () => import('../../components/homepage/HomepageHeader.vue' /* webpackChunkName: "components/homepage-header" */).then(c => wrapFunctional(c.default || c))
export const HomepageHeroNew = () => import('../../components/homepage/HomepageHeroNew.vue' /* webpackChunkName: "components/homepage-hero-new" */).then(c => wrapFunctional(c.default || c))
export const HomepageJoinCommunity = () => import('../../components/homepage/HomepageJoinCommunity.vue' /* webpackChunkName: "components/homepage-join-community" */).then(c => wrapFunctional(c.default || c))
export const HomepageKnowMoreSection = () => import('../../components/homepage/HomepageKnowMoreSection.vue' /* webpackChunkName: "components/homepage-know-more-section" */).then(c => wrapFunctional(c.default || c))
export const HomepageLogoBelt = () => import('../../components/homepage/HomepageLogoBelt.vue' /* webpackChunkName: "components/homepage-logo-belt" */).then(c => wrapFunctional(c.default || c))
export const HomepageLogobeltNew = () => import('../../components/homepage/HomepageLogobeltNew.vue' /* webpackChunkName: "components/homepage-logobelt-new" */).then(c => wrapFunctional(c.default || c))
export const HomepagePerformanceBoost = () => import('../../components/homepage/HomepagePerformanceBoost.vue' /* webpackChunkName: "components/homepage-performance-boost" */).then(c => wrapFunctional(c.default || c))
export const HomepageProcessDescription = () => import('../../components/homepage/HomepageProcessDescription.vue' /* webpackChunkName: "components/homepage-process-description" */).then(c => wrapFunctional(c.default || c))
export const HomepageTwitter = () => import('../../components/homepage/HomepageTwitter.vue' /* webpackChunkName: "components/homepage-twitter" */).then(c => wrapFunctional(c.default || c))
export const HomepageTwitterNew = () => import('../../components/homepage/HomepageTwitterNew.vue' /* webpackChunkName: "components/homepage-twitter-new" */).then(c => wrapFunctional(c.default || c))
export const HomepageUserHeaderBox = () => import('../../components/homepage/HomepageUserHeaderBox.vue' /* webpackChunkName: "components/homepage-user-header-box" */).then(c => wrapFunctional(c.default || c))
export const LiveopsWhitepaperCommonForm = () => import('../../components/liveops-whitepaper/CommonForm.vue' /* webpackChunkName: "components/liveops-whitepaper-common-form" */).then(c => wrapFunctional(c.default || c))
export const LiveopsWhitepaperHomepage = () => import('../../components/liveops-whitepaper/LiveopsWhitepaperHomepage.vue' /* webpackChunkName: "components/liveops-whitepaper-homepage" */).then(c => wrapFunctional(c.default || c))
export const LoadersPricingPageSkeletonLoader = () => import('../../components/loaders/PricingPageSkeletonLoader.vue' /* webpackChunkName: "components/loaders-pricing-page-skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const PageFooter = () => import('../../components/page/PageFooter.vue' /* webpackChunkName: "components/page-footer" */).then(c => wrapFunctional(c.default || c))
export const PageNotFound = () => import('../../components/page/PageNotFound.vue' /* webpackChunkName: "components/page-not-found" */).then(c => wrapFunctional(c.default || c))
export const PartnersCareersDescriptionSection = () => import('../../components/partners/CareersDescriptionSection.vue' /* webpackChunkName: "components/partners-careers-description-section" */).then(c => wrapFunctional(c.default || c))
export const PartnersDescriptionSection = () => import('../../components/partners/PartnersDescriptionSection.vue' /* webpackChunkName: "components/partners-description-section" */).then(c => wrapFunctional(c.default || c))
export const PartnersList = () => import('../../components/partners/PartnersList.vue' /* webpackChunkName: "components/partners-list" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowDownIcon = () => import('../../components/icons/ArrowDownIcon.vue' /* webpackChunkName: "components/icons-arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeftIcon = () => import('../../components/icons/ArrowLeftIcon.vue' /* webpackChunkName: "components/icons-arrow-left-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeftIconThin = () => import('../../components/icons/ArrowLeftIconThin.vue' /* webpackChunkName: "components/icons-arrow-left-icon-thin" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowLeftSharpThinIcon = () => import('../../components/icons/ArrowLeftSharpThinIcon.vue' /* webpackChunkName: "components/icons-arrow-left-sharp-thin-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowRightIcon = () => import('../../components/icons/ArrowRightIcon.vue' /* webpackChunkName: "components/icons-arrow-right-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowRightIconThin = () => import('../../components/icons/ArrowRightIconThin.vue' /* webpackChunkName: "components/icons-arrow-right-icon-thin" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowRightSharpThinIcon = () => import('../../components/icons/ArrowRightSharpThinIcon.vue' /* webpackChunkName: "components/icons-arrow-right-sharp-thin-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowTargetRightIcon = () => import('../../components/icons/ArrowTargetRightIcon.vue' /* webpackChunkName: "components/icons-arrow-target-right-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowThin = () => import('../../components/icons/ArrowThin.vue' /* webpackChunkName: "components/icons-arrow-thin" */).then(c => wrapFunctional(c.default || c))
export const IconsArrowUpLightIcon = () => import('../../components/icons/ArrowUpLightIcon.vue' /* webpackChunkName: "components/icons-arrow-up-light-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsBackToTopIcon = () => import('../../components/icons/BackToTopIcon.vue' /* webpackChunkName: "components/icons-back-to-top-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsBagIcon = () => import('../../components/icons/BagIcon.vue' /* webpackChunkName: "components/icons-bag-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsBellIcon = () => import('../../components/icons/BellIcon.vue' /* webpackChunkName: "components/icons-bell-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsBoltIcon = () => import('../../components/icons/BoltIcon.vue' /* webpackChunkName: "components/icons-bolt-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsBurgerMenuIcon = () => import('../../components/icons/BurgerMenuIcon.vue' /* webpackChunkName: "components/icons-burger-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckIcon = () => import('../../components/icons/CheckIcon.vue' /* webpackChunkName: "components/icons-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCheckRoundedIcon = () => import('../../components/icons/CheckRoundedIcon.vue' /* webpackChunkName: "components/icons-check-rounded-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCloseCircleIcon = () => import('../../components/icons/CloseCircleIcon.vue' /* webpackChunkName: "components/icons-close-circle-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCloseIcon = () => import('../../components/icons/CloseIcon.vue' /* webpackChunkName: "components/icons-close-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCloseIcon2 = () => import('../../components/icons/CloseIcon2.vue' /* webpackChunkName: "components/icons-close-icon2" */).then(c => wrapFunctional(c.default || c))
export const IconsCommunityNoResultsIcon = () => import('../../components/icons/CommunityNoResultsIcon.vue' /* webpackChunkName: "components/icons-community-no-results-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCreativeCommonsIcon1 = () => import('../../components/icons/CreativeCommonsIcon1.vue' /* webpackChunkName: "components/icons-creative-commons-icon1" */).then(c => wrapFunctional(c.default || c))
export const IconsCreativeCommonsIcon2 = () => import('../../components/icons/CreativeCommonsIcon2.vue' /* webpackChunkName: "components/icons-creative-commons-icon2" */).then(c => wrapFunctional(c.default || c))
export const IconsDefaultAvatarIcon = () => import('../../components/icons/DefaultAvatarIcon.vue' /* webpackChunkName: "components/icons-default-avatar-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDiagramThumbnailPlaceholder = () => import('../../components/icons/DiagramThumbnailPlaceholder.vue' /* webpackChunkName: "components/icons-diagram-thumbnail-placeholder" */).then(c => wrapFunctional(c.default || c))
export const IconsDotIcon = () => import('../../components/icons/DotIcon.vue' /* webpackChunkName: "components/icons-dot-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDoubleCheckIcon = () => import('../../components/icons/DoubleCheckIcon.vue' /* webpackChunkName: "components/icons-double-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsDownloadIcon = () => import('../../components/icons/DownloadIcon.vue' /* webpackChunkName: "components/icons-download-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsEthereumIcon = () => import('../../components/icons/EthereumIcon.vue' /* webpackChunkName: "components/icons-ethereum-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsExternalLinkIcon = () => import('../../components/icons/ExternalLinkIcon.vue' /* webpackChunkName: "components/icons-external-link-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFollowIcon = () => import('../../components/icons/FollowIcon.vue' /* webpackChunkName: "components/icons-follow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsFollowedIcon = () => import('../../components/icons/FollowedIcon.vue' /* webpackChunkName: "components/icons-followed-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsForkIcon = () => import('../../components/icons/ForkIcon.vue' /* webpackChunkName: "components/icons-fork-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsGlobeIcon = () => import('../../components/icons/GlobeIcon.vue' /* webpackChunkName: "components/icons-globe-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsHalfIcon = () => import('../../components/icons/HalfIcon.vue' /* webpackChunkName: "components/icons-half-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsHeartIcon = () => import('../../components/icons/HeartIcon.vue' /* webpackChunkName: "components/icons-heart-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsHireIcon = () => import('../../components/icons/HireIcon.vue' /* webpackChunkName: "components/icons-hire-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsInfoIcon = () => import('../../components/icons/InfoIcon.vue' /* webpackChunkName: "components/icons-info-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsLearningIcon = () => import('../../components/icons/LearningIcon.vue' /* webpackChunkName: "components/icons-learning-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsLinkedinIcon = () => import('../../components/icons/LinkedinIcon.vue' /* webpackChunkName: "components/icons-linkedin-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoMachination = () => import('../../components/icons/LogoMachination.vue' /* webpackChunkName: "components/icons-logo-machination" */).then(c => wrapFunctional(c.default || c))
export const IconsLogoutIcon = () => import('../../components/icons/LogoutIcon.vue' /* webpackChunkName: "components/icons-logout-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMachinationsLogoCustomColor = () => import('../../components/icons/MachinationsLogoCustomColor.vue' /* webpackChunkName: "components/icons-machinations-logo-custom-color" */).then(c => wrapFunctional(c.default || c))
export const IconsMailIcon = () => import('../../components/icons/MailIcon.vue' /* webpackChunkName: "components/icons-mail-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMapMarkerIcon = () => import('../../components/icons/MapMarkerIcon.vue' /* webpackChunkName: "components/icons-map-marker-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMenuIcon = () => import('../../components/icons/MenuIcon.vue' /* webpackChunkName: "components/icons-menu-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMessageIcon = () => import('../../components/icons/MessageIcon.vue' /* webpackChunkName: "components/icons-message-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsMinusIcon = () => import('../../components/icons/MinusIcon.vue' /* webpackChunkName: "components/icons-minus-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsNoIcon = () => import('../../components/icons/NoIcon.vue' /* webpackChunkName: "components/icons-no-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsNothingPublishedIcon = () => import('../../components/icons/NothingPublishedIcon.vue' /* webpackChunkName: "components/icons-nothing-published-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsNotificationIcon = () => import('../../components/icons/NotificationIcon.vue' /* webpackChunkName: "components/icons-notification-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlansPlusIcon = () => import('../../components/icons/PlansPlusIcon.vue' /* webpackChunkName: "components/icons-plans-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlayButton = () => import('../../components/icons/PlayButton.vue' /* webpackChunkName: "components/icons-play-button" */).then(c => wrapFunctional(c.default || c))
export const IconsPlayIcon = () => import('../../components/icons/PlayIcon.vue' /* webpackChunkName: "components/icons-play-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIcon = () => import('../../components/icons/PlusIcon.vue' /* webpackChunkName: "components/icons-plus-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsPlusIconSlim = () => import('../../components/icons/PlusIconSlim.vue' /* webpackChunkName: "components/icons-plus-icon-slim" */).then(c => wrapFunctional(c.default || c))
export const IconsScrollTopIcon = () => import('../../components/icons/ScrollTopIcon.vue' /* webpackChunkName: "components/icons-scroll-top-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchIcon = () => import('../../components/icons/SearchIcon.vue' /* webpackChunkName: "components/icons-search-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchIcon2 = () => import('../../components/icons/SearchIcon2.vue' /* webpackChunkName: "components/icons-search-icon2" */).then(c => wrapFunctional(c.default || c))
export const IconsSecurityIcon = () => import('../../components/icons/SecurityIcon.vue' /* webpackChunkName: "components/icons-security-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSelectArrowDownIcon = () => import('../../components/icons/SelectArrowDownIcon.vue' /* webpackChunkName: "components/icons-select-arrow-down-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsStarIcon = () => import('../../components/icons/StarIcon.vue' /* webpackChunkName: "components/icons-star-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTeamIcon = () => import('../../components/icons/TeamIcon.vue' /* webpackChunkName: "components/icons-team-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTestimonialLeftArrowIcon = () => import('../../components/icons/TestimonialLeftArrowIcon.vue' /* webpackChunkName: "components/icons-testimonial-left-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTestimonialRightArrowIcon = () => import('../../components/icons/TestimonialRightArrowIcon.vue' /* webpackChunkName: "components/icons-testimonial-right-arrow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTrendingIcon = () => import('../../components/icons/TrendingIcon.vue' /* webpackChunkName: "components/icons-trending-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsTrialBadgeIcon = () => import('../../components/icons/TrialBadgeIcon.vue' /* webpackChunkName: "components/icons-trial-badge-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsUndockIcon = () => import('../../components/icons/UndockIcon.vue' /* webpackChunkName: "components/icons-undock-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsUnfollowIcon = () => import('../../components/icons/UnfollowIcon.vue' /* webpackChunkName: "components/icons-unfollow-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsViewIcon = () => import('../../components/icons/ViewIcon.vue' /* webpackChunkName: "components/icons-view-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsVisibilityOffIcon = () => import('../../components/icons/VisibilityOffIcon.vue' /* webpackChunkName: "components/icons-visibility-off-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsWeb3CheckIcon = () => import('../../components/icons/Web3CheckIcon.vue' /* webpackChunkName: "components/icons-web3-check-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsWorldIcon = () => import('../../components/icons/WorldIcon.vue' /* webpackChunkName: "components/icons-world-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsYesIcon = () => import('../../components/icons/YesIcon.vue' /* webpackChunkName: "components/icons-yes-icon" */).then(c => wrapFunctional(c.default || c))
export const PricingAcademiaComponent = () => import('../../components/pricing/PricingAcademiaComponent.vue' /* webpackChunkName: "components/pricing-academia-component" */).then(c => wrapFunctional(c.default || c))
export const PricingLogoBelt = () => import('../../components/pricing/PricingLogoBelt.vue' /* webpackChunkName: "components/pricing-logo-belt" */).then(c => wrapFunctional(c.default || c))
export const PricingTypeform = () => import('../../components/pricing/PricingTypeform.vue' /* webpackChunkName: "components/pricing-typeform" */).then(c => wrapFunctional(c.default || c))
export const TermsHero = () => import('../../components/terms/TermsHero.vue' /* webpackChunkName: "components/terms-hero" */).then(c => wrapFunctional(c.default || c))
export const ProfileAvatar = () => import('../../components/profile/ProfileAvatar.vue' /* webpackChunkName: "components/profile-avatar" */).then(c => wrapFunctional(c.default || c))
export const ProfileCard = () => import('../../components/profile/ProfileCard.vue' /* webpackChunkName: "components/profile-card" */).then(c => wrapFunctional(c.default || c))
export const ProfileLocation = () => import('../../components/profile/ProfileLocation.vue' /* webpackChunkName: "components/profile-location" */).then(c => wrapFunctional(c.default || c))
export const ProfileSocialLinks = () => import('../../components/profile/ProfileSocialLinks.vue' /* webpackChunkName: "components/profile-social-links" */).then(c => wrapFunctional(c.default || c))
export const ResourcesItem = () => import('../../components/resources/ResourcesItem.vue' /* webpackChunkName: "components/resources-item" */).then(c => wrapFunctional(c.default || c))
export const ResourcesSection = () => import('../../components/resources/ResourcesSection.vue' /* webpackChunkName: "components/resources-section" */).then(c => wrapFunctional(c.default || c))
export const BlogSinglePost = () => import('../../components/blog/single-post/BlogSinglePost.vue' /* webpackChunkName: "components/blog-single-post" */).then(c => wrapFunctional(c.default || c))
export const BlogSinglePostAuthor = () => import('../../components/blog/single-post/BlogSinglePostAuthor.vue' /* webpackChunkName: "components/blog-single-post-author" */).then(c => wrapFunctional(c.default || c))
export const CommonAcfHero = () => import('../../components/common/acf/CommonAcfHero.vue' /* webpackChunkName: "components/common-acf-hero" */).then(c => wrapFunctional(c.default || c))
export const HeaderAuthHeader = () => import('../../components/header/auth/AuthHeader.vue' /* webpackChunkName: "components/header-auth-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderLogo = () => import('../../components/page/header/PageHeaderLogo.vue' /* webpackChunkName: "components/page-header-logo" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderLogoMobile = () => import('../../components/page/header/PageHeaderLogoMobile.vue' /* webpackChunkName: "components/page-header-logo-mobile" */).then(c => wrapFunctional(c.default || c))
export const IconsHomepageNewDesignIcon = () => import('../../components/icons/Homepage/NewDesignIcon.vue' /* webpackChunkName: "components/icons-homepage-new-design-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCompanyAddUserIcon = () => import('../../components/icons/Company/AddUserIcon.vue' /* webpackChunkName: "components/icons-company-add-user-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCompanyCodeIcon = () => import('../../components/icons/Company/CodeIcon.vue' /* webpackChunkName: "components/icons-company-code-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCompanyDollarIcon = () => import('../../components/icons/Company/DollarIcon.vue' /* webpackChunkName: "components/icons-company-dollar-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsCompanyStarsIcon = () => import('../../components/icons/Company/StarsIcon.vue' /* webpackChunkName: "components/icons-company-stars-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchAcademicIcon = () => import('../../components/icons/Search/AcademicIcon.vue' /* webpackChunkName: "components/icons-search-academic-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchAllIcon = () => import('../../components/icons/Search/AllIcon.vue' /* webpackChunkName: "components/icons-search-all-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchArticlesIcon = () => import('../../components/icons/Search/ArticlesIcon.vue' /* webpackChunkName: "components/icons-search-articles-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchDesignersIcon = () => import('../../components/icons/Search/DesignersIcon.vue' /* webpackChunkName: "components/icons-search-designers-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchDiagramsIcon = () => import('../../components/icons/Search/DiagramsIcon.vue' /* webpackChunkName: "components/icons-search-diagrams-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchDocumentationIcon = () => import('../../components/icons/Search/DocumentationIcon.vue' /* webpackChunkName: "components/icons-search-documentation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchGlossaryIcon = () => import('../../components/icons/Search/GlossaryIcon.vue' /* webpackChunkName: "components/icons-search-glossary-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchNoResultsAllIcon = () => import('../../components/icons/Search/NoResultsAllIcon.vue' /* webpackChunkName: "components/icons-search-no-results-all-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchNoResultsDesignersIcon = () => import('../../components/icons/Search/NoResultsDesignersIcon.vue' /* webpackChunkName: "components/icons-search-no-results-designers-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchNoResultsDiagramsIcon = () => import('../../components/icons/Search/NoResultsDiagramsIcon.vue' /* webpackChunkName: "components/icons-search-no-results-diagrams-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSearchNoResultsDocumentationIcon = () => import('../../components/icons/Search/NoResultsDocumentationIcon.vue' /* webpackChunkName: "components/icons-search-no-results-documentation-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialDiscordIcon = () => import('../../components/icons/social/DiscordIcon.vue' /* webpackChunkName: "components/icons-social-discord-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialFacebookIcon = () => import('../../components/icons/social/FacebookIcon.vue' /* webpackChunkName: "components/icons-social-facebook-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGoogleIcon = () => import('../../components/icons/social/GoogleIcon.vue' /* webpackChunkName: "components/icons-social-google-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialLinkedinIcon = () => import('../../components/icons/social/LinkedinIcon.vue' /* webpackChunkName: "components/icons-social-linkedin-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialTwitterIcon = () => import('../../components/icons/social/TwitterIcon.vue' /* webpackChunkName: "components/icons-social-twitter-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialCommuniyMenuDiscordCommIcon = () => import('../../components/icons/social/communiyMenu/DiscordCommIcon.vue' /* webpackChunkName: "components/icons-social-communiy-menu-discord-comm-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialCommuniyMenuTelegramCommIcon = () => import('../../components/icons/social/communiyMenu/TelegramCommIcon.vue' /* webpackChunkName: "components/icons-social-communiy-menu-telegram-comm-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialCommuniyMenuYoutubeCommIcon = () => import('../../components/icons/social/communiyMenu/YoutubeCommIcon.vue' /* webpackChunkName: "components/icons-social-communiy-menu-youtube-comm-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsDiscordSocialIcon = () => import('../../components/icons/social/game-socials/DiscordSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-discord-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsFacebookSocialIcon = () => import('../../components/icons/social/game-socials/FacebookSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-facebook-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsInstagramSocialIcon = () => import('../../components/icons/social/game-socials/InstagramSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-instagram-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsLinkedinSocialIcon = () => import('../../components/icons/social/game-socials/LinkedinSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-linkedin-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsRedditSocialIcon = () => import('../../components/icons/social/game-socials/RedditSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-reddit-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsTwitterFilledIcon = () => import('../../components/icons/social/game-socials/TwitterFilledIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-twitter-filled-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsTwitterSocialIcon = () => import('../../components/icons/social/game-socials/TwitterSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-twitter-social-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsSocialGameSocialsYoutubeSocialIcon = () => import('../../components/icons/social/game-socials/YoutubeSocialIcon.vue' /* webpackChunkName: "components/icons-social-game-socials-youtube-social-icon" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
